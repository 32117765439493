<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-card>
        <v-toolbar dense dark class="secondary">
          <v-toolbar-title>Clients summary guide</v-toolbar-title>
        </v-toolbar>
        <v-progress-linear style="position: absolute" v-show="loading" :indeterminate="true" class="ma-0"></v-progress-linear>
        <v-card-text>
          <table class="v-datatable v-table theme--light print-font-small bill">
            <thead>
              <tr style="height: auto">
                <th class="text-xs-left py-0">Fullname</th>
                <th class="text-xs-left py-0">License No</th>
                <th class="text-xs-left py-0">NPI</th>
                <th class="text-xs-left py-0">ProviderId</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="i in users" :key="'clientid' + i.userId" :class="{ 'red lighten-5': !i.active }">
                <td>
                  <strong>{{ i.firstname }} {{ i.lastname }}</strong>
                </td>
                <td>
                  <strong>{{ i.licenseNo }}</strong>
                </td>
                <td>{{ i.npi }}</td>
                <td>{{ i.mpi }}</td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import userApi from "@/services/api/UserServices";

export default {
  data() {
    return {
      loading: false,
      users: []
    };
  },

  mounted() {
    this.$store.commit("SET_ACTIVE_CLIENT", 0);
    this.loadUser();
  },

  methods: {
    async loadUser() {
      this.users = [];
      this.loading = true;
      try {
        let users = await userApi.getUsers();
        this.users = users.filter((s) => s.active).sort((a, b) => (a.firstname.toLowerCase() > b.firstname.toLowerCase() ? 1 : -1));
        console.log(this.users);
      } catch (error) {
        console.log(error);
        this.$toast.error(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
